@font-face {
  font-family: 'Eksell-Large';
  src: url('./fonts/EksellDisplay-Large.otf');
}

@font-face {
  font-family: 'Eksell-Small';
  src: url('./fonts/EksellDisplay-Small.otf');
}

@font-face {
  font-family: 'Neuzeit';
  src: url('./fonts/NeuzeitSLTStd-Book.otf');
}

@font-face {
  font-family: 'Neuzeit-Bold';
  src: url('./fonts/NeuzeitSLTStd-BookHeavy.otf');
}

@font-face {
  font-family: 'Apollo';
  src: url('./fonts/apollo.otf');
}

@font-face {
  font-family: 'Apollo Italics';
  src: url('./fonts/apollo-ital.otf');
}

@font-face {
  font-family: 'Apollo Semibold';
  src: url('./fonts/apollo-semi.otf');
}

@font-face {
  font-family: 'Avant';
  src: url('./fonts/itc-avant.otf');
}

@font-face {
  font-family: 'Domaine Bold';
  src: url('./fonts/DomaineDispNar-Bold.otf');
}

@font-face {
  font-family: 'Domaine Regular';
  src: url('./fonts/DomaineDispNar-Regular.otf');
}

@font-face {
  font-family: 'Domaine Semibold';
  src: url('./fonts/DomaineDispNar-Semibold.otf');
}

@font-face {
  font-family: 'Domaine Medium';
  src: url('./fonts/DomaineDispNar-Medium.otf');
}

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #332926;
}

body {
  background: #fff;
}

body.homepage {
  overflow: hidden;
}

a {
  color: #146f91;
}

.hanumanLogo a {
  text-decoration: none;
  color: #332926;

}

.appContainer {
  position: relative;
  z-index: 2;
  padding: 40px;
  height: calc(100% - 80px);
  width: calc(100% - 80px);
}

.appRouter {
  height: 100%;
  width: 100%;
}

.bgNoise {
  position: fixed;
  height: 100%;
  width: 100%;
  background: url('./noise2.png');
  background-size: auto;
  background-size: inherit;
  top: 0;
  left: 0;
  mix-blend-mode: hard-light;
  opacity: 0.4;
  z-index: 1;
}

.hanumanLogo {
  font-family: Eksell-Large;
  font-size: 76px;
  letter-spacing: 3px;
  line-height: 70px;
  text-transform: uppercase;
}

.hpText {
  position: absolute;
  bottom: 28px;
  right: 40px;
  z-index: 32;
  max-width: 1000px;
  font-size: 23px;
  font-family: 'Neuzeit-Bold';
  line-height: 1.6em;
  color: #d5d2b8;
}

.hpText a {
  font-size: 17px;
}

.mainText {
  max-width: 1280px;
  line-height: 1.6em;
  gap: 80px;
  font-size: 20px;
  font-family: 'Apollo';
  hyphens: auto;
}

.mainText img {
  max-width: 1280px;
  margin-top: 24px;
}

figcaption {
  font-family: 'Neuzeit';
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  display: block;
}

.centeredImageContainer {
  width: 100%;
  max-width: 1280px;
}

.center {
  max-width: 40%;
  margin-left: auto;
  margin-top: 56px;
  margin-bottom: 56px;
  margin-right: auto;
}

figure.floatRight {
  float: right;
  margin: 0 0 48px 48px;
  width: 30%;
}

figure.left {
  float: left;
  margin: 0 48px 48px 0;
  width: 30%;
}

figure img {
  max-width: 100% !important;
  margin-top: 0 !important;
}

.seriesGraf {
  margin-bottom: 48px !important;
}

.seriesSubhead {
  text-align: center;
  color: #332926;
  display: inline-block;
  width: max-content;
  margin: 0px auto 24px auto;
  font-size: 21px;
}

.lessMarginBottom {
  margin-bottom: 36px;
}

.boxGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 128px 121px;
  margin-top: 48px;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.mGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 56px;
  margin-bottom: 56px;
}

.mGrid-item img,
.boxGrid-item img {
  max-width: 100%;
  margin: 0;
  display: inline-block;
}



.booksPage p {
  line-height: 1.6em;
  gap: 80px;
  font-size: 20px;
  margin: 0 auto;
  font-family: 'Apollo';
  text-align: left;
  hyphens: auto;
}

.mainText.booksPage {
  margin: 14% 10% 80px 10%;
  max-width: 80%;
}

.mainText p em {
  font-family: 'Apollo Italics';
  font-style: normal;
}

.bold,
h2 {
  font-family: 'Neuzeit-Bold';
}

.bold {
  font-size: 18px;
}

h1 {
  font-family: 'Eksell-Small';
  font-weight: normal;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0px 0 24px 0;
  color: #332926;
  display: inline-block;
  width: max-content;
  max-width: 100%;
  line-height: 36px;
}

h1.n2-book-title {
  font-size: 28px;
}

h1:after {
  background: none;
  content: attr(data-text);
  left: 0;
  position: absolute;
  text-shadow: 0px -1px #5b5228, 0px 1px 1px #fce494;
  top: 0;
  z-index: -1;
}


h2 {
  font-family: 'Eksell-Small';
  font-weight: normal;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 48px 0 96px 0;
}

h2.gold {
  color: #332926;
  display: inline-block;
  margin: 108px auto 24px auto;
}

.gold {
  color: #332926;
}

.booksPage {
  display: flex;
  flex-direction: column;
}

h2.gold::after {
  background: none;
  content: attr(data-text);
  left: 0;
  position: absolute;
  text-shadow: 0px -1px #5b5228, 0px 1px 1px #fce494;
  top: 0;
  z-index: -1;
}

.signOff {
  font-family: 'Apollo';
  font-size: 20px;
  text-align: left;
  margin-left: 60%;
}

hr {
  border: 1px solid #332926;
  margin: 48px;
}

.pageContainer {
  margin: 8% 12% 80px 12%;
  padding-bottom: 96px;
  position: relative;
}

.pageContainer.booksLanding {
  margin: 8% 12% 80px 10%;
}

button.primaryButton {
  background: none;
  border: 2px solid #d5d2b8;
  color: #d5d2b8;
  font-size: 20px;
  padding: 4px 12px;
  cursor: pointer;
}

.eksellInline {
  font-family: 'Eksell-Small';
  letter-spacing: .5px;
  font-size: 19px;
}

.emailSignup {
  display: flex;
  gap: 24px;
  margin-top: 96px;
}

.emailSignup .inputControl {
  flex: 4;
}

.signup-button {
  padding: 8px 12px;
  background: none;
  outline: none;
  border: 2px solid #332926;
  font-family: 'Neuzeit';
  border-radius: 0px;
  font-size: 18px;
  color: #332926;
  cursor: pointer;
}

.emailSignup button {
  margin-bottom: 20px;
}

.emailSuccess {
  font-family: 'Neuzeit-Bold';
  margin-top: 96px;
}

.hp-more {
  text-transform: uppercase;
}

.menu {
  position: sticky;
  margin: 14% 0 0 0;
  padding: 0;
  top: 14%;
  float: left;
  font-family: 'Domaine Medium';
}

.menu li {
  list-style-type: none;
  font-size: 26px;
  margin-bottom: 20px;
}

.menu li.sub-menu {
  list-style-type: none;
  font-size: 22px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.chosen {
  border-bottom: 2px solid #d5d2b8;

}

.menu li a {
  text-decoration: none;
}

.stock-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px 20px;
}

.stockist {
  line-height: 1.2;
}

.stock-location {
  font-size: 14px;
  font-family: 'Neuzeit-Bold';
}

.stock-territory {
  margin: 48px 0 48px;
}

/** Monkey! **/

.book {
  width: 18vw;
  height: 24vw;
  position: absolute;
  top: calc(50% - 9vw);
  left: 41%;
  perspective: 100px;
  z-index: 30;
  cursor: pointer;
}

.cover {
  width: 100%;
  height: 100%;
  background: #bf8ac6;
  position: absolute;
  transform-style: preserve-3d;
  transform: rotate3d(0, 0, 0, 0) translate3d(0, 0, 0);
  transition: background-color 0.4s linear;
  z-index: 40;
  overflow: hidden;
  box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, .5),
    1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
}

.cover img {
  height: 100%;
  mix-blend-mode: multiply;
}

.border {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  /*transition: all 0.4s linear;*/
  transform-style: preserve-3d;
  transform: rotate3d(0, 0, 0, 0) translate3d(0, 0, 0);
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #e4e2da;
}


.monkey {
  position: absolute;
  z-index: 1001;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  width: 110px;
}

.monkey svg {
  position: absolute;
}

.monkey svg:first-child {
  z-index: 2;
}

.monkey svg:last-child {
  z-index: 6;
}

.background svg {
  width: 32%;
  top: 40px;
  left: 40px;
  position: absolute;
}

.background #Layer_1-2 {
  fill: #332926;
}

.book .border.zero {
  z-index: 25;
  filter: brightness(100%);

}

.book .border.one {
  margin-top: 8px;
  margin-left: 8px;
  filter: brightness(97%);
  z-index: 24;
}

.book .border.two {
  margin-top: 16px;
  margin-left: 16px;
  filter: brightness(96%);
  z-index: 23;
}

.book .border.three {
  margin-top: 24px;
  margin-left: 24px;
  filter: brightness(94%);
  z-index: 22;
}

.book .border.four {
  margin-top: 32px;
  margin-left: 32px;
  filter: brightness(92%);
  z-index: 21;
}

.book .border.five {
  margin-top: 40px;
  margin-left: 40px;
  filter: brightness(90%);
  z-index: 20;
}

.book .border.six {
  margin-top: 48px;
  margin-left: 48px;
  filter: brightness(88%);
  z-index: 19;
}

.book .border.seven {
  margin-top: 56px;
  margin-left: 56px;
  filter: brightness(87%);
  z-index: 18;
}

#Layer_2-2 {
  fill: url(#gold-gradient);
  stroke-width: 0.4%;
  stroke: url(#gold-gradient-inverse);
}

.mobText {
  font-size: 20px;
  font-family: 'Neuzeit-Bold';
  line-height: 1.6em;
  color: #d5d2b8;
  display: none;
  margin: 48px 0 24px;
}

.finePrint {
  font-family: 'Neuzeit';
  font-size: 16px;
}

.mobileMenu {
  display: none;
}

#mc_embed_signup_scroll {
  display: flex;
  gap: 24px;
}

#mc-embedded-subscribe {
  background: none;
  border: 2px solid #d5d2b8;
  color: #d5d2b8;
  font-size: 20px;
  padding: 4px 12px;
}

#mce-EMAIL {
  padding: 10px 12px;
  width: calc(100% - 24px);
  background: #d5d2b8;
  outline: none;
  border: 0;
  font-family: 'Neuzeit';
  border-radius: 0px;
  -webkit-appearance: none;
}

#mc_embed_shell {
  margin-top: 40px;
}

.mc-field-group {
  min-width: 60%;
}

.productList {
  display: grid;
  grid-template-columns: 240px 3fr;
  gap: 40px;
}

.productList.boxSet {
  display: grid;
  grid-template-columns: 360px 3fr;
  gap: 40px;
}

h2.productTitle {
  font-family: 'Eksell-Large';
  font-size: 26px;
  letter-spacing: 2px;
  margin-top: 0px;
  margin-bottom: 4px;
}

h2.new-productTitle {
  font-family: 'Eksell-Large';
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.new-product-controls {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
  width: 70%;
  margin: 0 auto 0;
}

.new-product-controls.box {
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
}

.nb-title {
  /*font-family: 'Neuzeit-Bold';*/
}

.nb-info {
  font-size: 15px;
}

.finePrint {
  font-family: 'Neuzeit' !important;
  font-size: 14px !important;
  margin-top: -12px !important;
}

.boxColor {
  text-align: left;
}

.new-product-data {
  width: 64%;
  margin: 0 auto 8px;
}

#cart-icon-small {
  width: 32px;
  height: 24px;
}

.down-icon {
  width: 23px;
  margin-left: 24px;
  transform: rotate(0deg);
  transition: all 0.1s ease;
  cursor: pointer;
}

.down-icon.invert {
  transform: rotate(180deg);
}

.new-product-controls a:first-child {
  text-align: left;
}

.new-product-controls a:last-child {
  text-align: right;
}

.new-productAuthor {
  font-family: 'Neuzeit-Bold';
  font-size: 18px;
  line-height: 16px;
}

.cover-placeholder {
  aspect-ratio: 3/4;
  background: #d5d2b8;
  width: 100%;
}

.productAuthor {
  font-family: 'Neuzeit-Bold';
  font-size: 18px;
}

.productStats {
  font-size: 14px;
  font-family: 'Neuzeit-Bold';
  letter-spacing: 1px;
}

.productImage {
  max-width: 240px;
}


.boxSet .productImage {
  max-width: 360px;
}

.productImage img {
  max-width: 100%;
}

strong {
  font-family: 'Neuzeit-Bold';
  font-size: 18px;
}

.productBuy {
  display: block;
  border: 2px solid #332926;
  color: #332926;
  font-size: 20px;
  padding: 4px 12px;
  cursor: pointer;
  font-family: 'Neuzeit-Bold';
  text-decoration: none;
  text-align: center;
  margin-bottom: 48px;
  max-width: 300px;
  transition: all 0.2s ease;
}

.productBuy:hover {
  border-color: #4e93ac;
  color: #4e93ac;
}

.introGrafAbout {
  font-size: 24px;
  line-height: 1.4em;
}

.shrutsIntro {
  font-family: 'Neuzeit-Bold';
  margin-top: 52px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: normal;
}

.productBuySlim {
  display: block;
  border: 2px solid #d5d2b8;
  color: #d5d2b8;
  font-size: 18px;
  padding: 2px 12px;
  cursor: pointer;
  font-family: 'Neuzeit-Bold';
  text-decoration: none;
  text-align: center;
  margin-bottom: 4px;
}

.productBuySlim .variant {
  font-size: 13px;
}

.new-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 48px 0px;
  margin-top: 58px;
  margin-bottom: 24px;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.new-grid-cover {
  width: 72%;
  margin: 0 auto 12px;
  position: relative;
  cursor: pointer;
}

.book-cover {
  position: relative;
  z-index: 20;

}

.booksLanding h2 {
  margin-bottom: 24px;
}

.subImageContainer {
  margin-left: 24px;
  margin-bottom: 48px;
}

.subImage {
  max-width: 500px;
  position: relative;
  margin-left: auto;
  margin-right: 24px;
}

.subImage img {
  max-width: 100%;
  position: relative;
  z-index: 3;
  display: block;
}

.subBg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all 0.6s linear;
}

.acc-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.6s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
}

.acc-image-one {
  margin-top: -6px;
  margin-left: 10px;
  z-index: 18;
}

.acc-image-two {
  margin-top: -12px;
  margin-left: 20px;
  z-index: 17;
}

.acc-image-three {
  margin-top: -18px;
  margin-left: 30px;
  z-index: 16;
}

.acc-image-four {
  margin-top: -24px;
  margin-left: 40px;
  z-index: 15;
}

.acc-image-five {
  margin-top: -30px;
  margin-left: 50px;
  z-index: 14;
}

.acc-image-six {
  margin-top: -36px;
  margin-left: 60px;
  z-index: 13;
}

.n2-book-images {
  display: flex;
  gap: 30px;
  margin-bottom: 120px;
}

.n2-image img {
  max-width: 100%;
}

.image-grid {
  display: flex;
  justify-content: space-between;
}

.image-grid img {
  max-width: 24%;
}

.image-grid.mixed {
  margin-bottom: 64px;
}

.image-grid.mixed img {
  max-height: 400px;
  max-width: max-content;
}

.n2-book-title {
  margin-bottom: 4px;
}

.n2-book-author {
  font-family: 'Apollo Italics';
  font-size: 26px;
  margin-bottom: 40px;
}

.n2-book-stats {
  font-family: 'Neuzeit-Bold';
  font-size: 15px;
  line-height: 1.8em;
  margin-bottom: 48px;
}

.n2-book-series {
  font-family: 'Neuzeit-Bold';
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 12px;
}


.new-grid-item img {
  max-width: 100%;
  display: block;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.acc-image img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.soldOut {
  font-family: 'Neuzeit-Bold';
}

.new-grid-item .new-grid-cover::before {
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 92%);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 8px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.bigger {
  font-size: 24px;
  line-height: 1.6em;
}

#cart-icon {
  width: 60px;
  cursor: pointer;
}

#menu-icon {
  width: 24px;
  cursor: pointer;
}

.hpCart #cart-icon-small {
  width: 34px;
  display: block;
  height: 25px;
}

#close-icon {
  width: 30px;
  cursor: pointer;
}

.cart-stroke-one {
  fill: none;
}

.cart-stroke-one,
.cart-stroke-two {
  stroke: #332926;
  stroke-miterlimit: 10;
  stroke-width: 12px;
}

.hpCart #cart-icon-small .cart-stroke-one,
.hpCart #cart-icon-small .cart-stroke-two {
  stroke: #332926;
}



.cart-stroke-two {
  fill: none;
  stroke-linecap: round;
}

.menu-stroke {
  fill: none;
  stroke: #332926;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 12px;
}

.new-menu-icons {
  position: fixed;
  top: 40px;
  right: 40px;
  display: flex;
  gap: 22px;
}

.new-menu-container {
  position: fixed;
  z-index: 40;
  top: 0;
  right: 0;
  padding: 40px;
  background: #332926;
  color: #d5d2b8;
  width: 220px;
  transition: all 0.6s ease;
  margin-right: -300px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.new-menu-container.new-menu-open {
  margin-right: 0px;
}

.close-container {
  text-align: right;
}

.new-menu {
  list-style-type: none;
  font-family: 'Neuzeit';
  padding: 0;
}

.new-menu li {
  list-style-type: none;
  font-size: 20px;
  margin-bottom: 20px;
}

.new-menu li:last-child {
  margin-bottom: 0px;
}

.new-menu li.sub-menu {
  list-style-type: none;
  font-size: 19px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.new-menu a {
  color: #d5d2b8;
  text-decoration: none;
}

.new-menu a:hover {
  text-decoration: underline;
}

.close-stroke {
  fill: none;
  stroke: #d5d2b8;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 12px;
}

.down-stroke {
  fill: none;
  stroke: #d5d2b8;
  stroke-linecap: round;
  stroke-miterlimit: 12;
  stroke-width: 12px;
}

.leftGraf {
  max-width: 700px;
  margin-bottom: 64px;
}

.rightGraf {
  max-width: 700px;
  margin-left: calc(100% - 700px);
  margin-bottom: 64px;
}

.buyingOptions {
  margin-top: 24px;
}


.buyLinks {
  margin-top: 24px;
}

.buyLinks a {
  display: block;
  text-decoration: none;
}

.cartCount {
  color: #eaeaea;
  background: #186580;
  display: inline-flex;
  padding: 2px 8px;
  text-align: center;
  border-radius: 2rem;
  font-family: 'Neuzeit-Bold';
  margin-top: -8px;
  font-size: 12px;
}

.cartCountNo {
  margin: auto;
}

.hpCart a {
  display: flex;
}

@media (max-width: 1500px) {
  .hpText {
    font-size: 20px;
  }

  .hanumanLogo {
    font-size: 70px;
    line-height: 64px;
  }
}

@media (max-width: 1200px) {
  .pageContainer.booksLanding {
    margin: 8% 18% 80px 10%;
  }
}

@media (max-height: 840px) {
  .hanumanLogo {
    font-size: 72px;
    line-height: 66px;
    letter-spacing: 3px;
  }
}

@media (max-width: 800px) {

  .new-grid {
    display: flex;
    flex-direction: column;
    gap: 68px;
    margin-top: 24px;
  }

  .subImage {
    max-width: 100%;
  }

  .booksPage p {
    font-size: 18px;
    text-align: left;
  }

  .new-grid-cover {
    width: 73%;
    margin: 0 auto 12px auto;
  }

  .new-product-controls {
    width: 73%;
    margin: 0 auto 12px auto;
  }

  .leftGraf {
    max-width: 100%;
    margin-bottom: 48px;
    text-align: left;
  }

  .new-menu-container {
    width: 168px;
    padding: 24px;
  }

  #close-icon {
    width: 20px;
  }

  .new-menu li {
    font-size: 18px;
  }

  .down-icon {
    width: 26px;
  }

  .boxGrid {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .rightGraf {
    max-width: 100%;
    margin-bottom: 48px;
    margin-left: 0;
    text-align: left;
  }

  .center {
    margin: 0;
    max-width: 100%;
  }

  .new-product-controls.box {
    margin: 6px 0 0 0;
  }

  .mGrid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .mainText img {
    max-width: 100%;
    margin-top: 24px;
  }

  strong {
    font-size: 16px;
  }

  .mGrid img {
    margin: 0;
    display: block;
  }

  h2.productTitle {
    font-family: 'Eksell-Large';
    font-size: 23px;
  }

  h2 {
    font-size: 27px;
  }

  .productList,
  .productList.boxSet {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .signOff {
    margin-left: 40%;
  }

  .mobileMenu {
    display: flex;
    position: sticky;
    top: 0;
    padding: 0;
    gap: 15px;
    padding-top: 20px;
  }

  .pageContainer {
    padding-right: 0;
    margin-top: 48px;
    margin: 24px 0 48px 0px;
  }

  .pageContainer.booksLanding {
    margin: 120px 0 48px 0;
  }

  .hpText {
    left: 40px;
    text-align: center;
    bottom: 0px;
    display: none;
  }

  .stock-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .introGrafAbout {
    font-size: 22px;
  }

  .mobileMenu li {
    list-style-type: none;
    font-family: 'Neuzeit-Bold';
  }

  .mobileMenu li a {
    text-decoration: none;
  }

  .appContainer {
    padding: 24px;
    width: calc(100% - 48px);
  }

  .hanumanLogo {
    font-size: 28px;
    line-height: 25px;
  }


  .new-menu-icons {
    position: unset;
    gap: 12px;
  }

  .hpCart #cart-icon-small {
    width: 32px;
  }

  #menu-icon {
    width: 32px;
  }

  .menu {
    position: unset;
    margin: 8% 0 22%;
    padding: 0;
    display: none;
  }

  .mainText {
    margin: 0;
    padding-top: 48px;
    font-size: 18px;
    line-height: 1.4em;
  }

  .book {
    width: 60vw;
    height: 80vw;
    top: calc(50% - 15vw);
    left: 20vw;
    perspective: 120px;
    z-index: 30;
  }

  .mobHide {
    display: none;
  }

  .mobText {
    display: block;
  }

  .productBuy {
    font-size: 16px;
    width: max-content;
  }

  h1 {
    font-size: 23px;
  }

  .new-product-controls {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
  }

  .App-header {
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, .2);
    margin: 0 -24px -24px;
    padding: 18px 24px 12px;
    position: fixed;
    top: 0;
    width: calc(100% - 48px);
    z-index: 24;
    display: flex;
    justify-content: space-between;
  }

  .new-menu {
    margin: auto 0;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  .cartCount {
    padding: 2px 8px 0;
  }
}