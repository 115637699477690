.addToCartButton {
  background: none;
  outline: none;
  border: 2px solid #eaeaea;
  display: flex;
  gap: 4px;
  cursor: pointer;
  font-family: 'Neuzeit';
  transition: all 0.2s linear;
}

.addToCartButton:hover {
  border: 2px solid #186580;
}

.addToCartButton #cart-icon-small {
  width: 24px;
  height: 24px;
}

.addToCartButton span {
  /* padding-top: 3px; */
}

/* Alt

.addToCartButton.alt {
  background-color: #186580;
  color: #eaeaea;
  border-color: #186580;
}

.addToCartButton.alt:hover {
  background-color: #154b5e;
}

.addToCartButton.alt .cart-stroke-one,
.addToCartButton.alt .cart-stroke-two {
  stroke: #eaeaea !important;
} */

/* Product */

.addToCartButton.product {
  gap: 6px;
  padding: 2px 8px;
}

.addToCartButton.product {
  font-size: 20px;
}

.addToCartButton.product #cart-icon-small {
  width: 32px;
  height: 32px;
}

.addToCartButton:disabled {
  color: #eaeaea;
}

.addToCartButton:hover {
  border: 2px solid #eaeaea;
}

@media (max-width: 720px) {
  .addToCartButton {
    display: inline;
    font-size: 16px;
    padding: 6px 12px;
    color: #000;
  }
}