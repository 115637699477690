.n-book {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 40px;
  margin: 12%;
}

.n-book-data {
  grid-column: span 3;
  margin-left: 48px;
  margin-left: 24%;
}

.n-book-images {
  grid-column: span 4;
  min-height: 100px;
  position: relative;
  margin-bottom: 48px;
}

.n-book-media {
  grid-column: span 1;
  min-height: 100px;
}

.n-image {
  width: 23%;
}

.n-primary-image {
  z-index: 10;
  position: relative;
  transition: all 0.4s ease;
  margin-left: 0;
  width: 24%;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
}

.n-primary-image img {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.n-image-1 {
  z-index: 8;
  position: absolute;
  top: 0;
  transition: all 0.4s ease;
  margin-left: 1%;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.n-image-2 {
  z-index: 7;
  position: absolute;
  top: 0;
  transition: all 0.4s ease;
  margin-left: 1%;
}

.n-image-3 {
  z-index: 6;
  position: absolute;
  transition: all 0.4s ease;
  top: 0;
  margin-left: 1%;
}

.n-image-spine.n-image img {
  height: 100%;
  z-index: 6;
  transition: all 0.4s ease;
  top: 0;
  position: absolute;
  border-radius: 3px;
}

.n-image-back {
  z-index: 5;
  position: absolute;
  margin-left: 1%;
  transition: all 0.4s ease;
  top: 0;
  border-radius: 0;
  overflow: hidden;
}

.n-image-spine {
  z-index: 9;
  transition: all 0.4s ease;
  margin-left: 1%;
  top: 0;
  position: absolute;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) inset, -25px 0 20px -20px rgba(0, 0, 0, 0.45);
  aspect-ratio: 63 / 932;
}

.n-image-spine::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  width: 16px;
  z-index: 14;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .3) 90%);
}

.n-image-back::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  z-index: 2;
  right: 0;
  width: 64px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 90%);

}

.n-primary-image::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 16px;
  z-index: 2;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 90%)
}

.n-image img {
  width: 100%;
  display: block;
}

.n-book-title {
  font-family: 'Eksell-Large';
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 24px;
  max-width: 100%;
}

.n-book-author {
  font-family: 'Neuzeit-Bold';
  font-size: 18px;
}

.n-book-images:hover .n-image {
  width: 24%;
}

.n-book-images:hover .n-primary-image::before {
  width: 64px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 90%);
}

.n-book-images:hover .n-image-spine::before {
  width: 100%;
  z-index: 14;
  border-radius: 0;
  background: linear-gradient(90deg, rgba(99, 99, 91, 0.12) 0%, rgba(255, 255, 255, 0) 50%, rgba(66, 66, 73, 0.12) 100%);
}

.n-book-images:hover .n-image-1 {
  margin-left: 28%;
}

.n-book-images:hover .n-image-2 {
  margin-left: 50%;
}

.n-book-images:hover .n-image-3 {
  margin-left: 74%;
}

.n-book-images:hover .n-primary-image {
  margin-left: 26.4%;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.n-book-images:hover .n-image-back {
  margin-left: 0;
}

.n-book-images:hover .n-image-spine {
  margin-left: 24%;
  width: auto;
}

.n-book-images:hover .n-image-spine.n-image img {
  border-radius: 0;
}


.n-book-description {
  margin-top: 48px;
  max-width: 800px;
  line-height: 1.8em;
  font-size: 17px;
}

.n-book-bio {
  max-width: 800px;
  margin-top: 24px;
  line-height: 1.8em;
  font-size: 17px;
}

.boxGrid-item {
  position: relative;
}

.boxImage::before {
  background: #d29a10;
  content: ' ';
  position: absolute;
  height: calc(100% - 38px);
  width: 24px;
  right: -24px;
  transform: skew(0, -48deg);
  top: -14px;
}

.boxImage::after {
  background: #f4c142;
  content: ' ';
  position: absolute;
  top: -27px;
  height: 28px;
  width: 100%;
  transform: skew(-42deg, 0);
  left: 12px;
}

@media (max-width: 720px) {
  .n-book-images {
    margin-top: 64px;
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }

  .n-image {
    width: 100%;
  }

  .n-image-1,
  .n-image-2,
  .n-image-back,
  .n-image-3,
  .n-image-spine {
    display: none;
  }

  .n-book-images:hover .n-image {
    width: 100%;
  }

  .n-book-images:hover .n-primary-image::before {
    width: 16px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 90%);
  }

  .n-book-images:hover .n-image-1 {
    margin-left: 0;
  }

  .n-book-images:hover .n-image-2 {
    margin-left: 0;
  }

  .n-book-images:hover .n-image-3 {
    margin-left: 0;
  }

  .n-book-images:hover .n-primary-image {
    margin-left: 0;
    ;
  }

  .n-book-images:hover .n-image-back {
    margin-left: 0;
  }

  .n-book-images:hover .n-image-spine {
    margin-left: 0;
    width: auto;
  }

  .n-book-images:hover .n-image-spine.n-image img {
    border-radius: 0;
  }
}