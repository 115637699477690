.npc-container {
  overflow: hidden;
}

.npc-card {
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  width: calc(100% - 48px);
  height: calc(50% - 48px);
  margin-top: -90%;
  transition: all 0.4s ease;
  z-index: 23;
}

.npc-container:hover .npc-card {
  margin-top: 25%;
}

.npc-container .book-cover {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.npc-container:hover .book-cover {
  opacity: 0.14;
}

.npc-container .new-product-controls {
  display: flex;
  flex-direction: column;
}

.npc-container .nb-info {
  text-align: center;
}

.npc-card .addToCartButton {
  border-color: #186580;
  padding: 4px 20px;
  margin: 28px auto 28px;
}

.npc-container.new-grid-cover::before {
  background: none;
}

.npc-learn {
  text-align: center;
  font-family: 'Apollo';
}

.npc-card .nb-title {
  font-family: 'Eksell-Small';
  font-size: 23px;
}