hr {
  margin: 48px 0;
  background: #b7bec0;
  border-color: #b7bec0;
  border-top: 1px;
}

.inputControl {
  margin-bottom: 12px;
}

.inputControl__label,
.pseudoLabel {
  font-family: 'Neuzeit-Bold';
  margin-bottom: 12px;
  display: block;
  opacity: .8;
  font-size: 18px;
}

p.form-description {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 1.6em;
}

.inputControl input,
.inputControl select {
  padding: 10px 12px;
  width: calc(100% - 24px);
  background: #eaeaea;
  outline: none;
  border: 0;
  font-family: 'Neuzeit';
  border-radius: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  font-size: 18px;
  color: #332926
}


.inputControl textarea {
  padding: 12px;
  width: calc(100% - 24px);
  font-family: 'Neuzeit';
  background: #dcdcd3;
  border: 0;
  line-height: 1.6rem;
}

.inputControl input.title {
  font-size: 20px;
}

.inputControl__help {
  font-size: 13px;
  margin-top: 8px;
  display: block;
  opacity: .68;
}

.binaryChooser {
  display: flex;
}

.binaryChooser__opt {
  padding: 4px 12px;
  vertical-align: top;
  font-size: 18px;
  background: #eaeaea;
}

.binaryControl.large .binaryChooser__opt {
  font-size: 26px;
  padding: 12px 24px;
}

.binaryControl.large .binaryChooser {
  justify-content: center;
}

.large .inputControl__label {
  font-size: 20px;
  text-align: center;
}

.binaryChooser__opt:first-child {
  border-radius: 4px 0 0 4px;
}

.binaryChooser__opt:last-child {
  border-radius: 0 4px 4px 0;
}

.binaryChooser__opt-chosen {
  background: #186580;
  color: #eaeaea;
}

.peopleChooser {
  margin-bottom: 20px;
}

.peopleChooserControls {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.peopleChooser__name {
  flex: 4;
}

.peopleChooser__title {
  flex: 2;
}

.peopleChooser__list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.peopleChooser__person {
  background: #00374b;
  color: #d5d2b8;
  padding: 4px 12px;
  display: flex;
  border-radius: 4px;
  gap: 24px;
}

img.imagePreview {
  max-width: 100%;
  display: block;
  margin-bottom: 10px;
}

.categoryList,
.relatedProducts {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  display: inline-flex;
  gap: 12px;
}

.relatedProducts {
  margin-top: 20px;
}

.categoryPill,
.relatedProduct {
  background: #b7bec0;
  padding: 4px 12px;
  border-radius: 4px;
  border: 0;
}

.categoryPill-active {
  background: #00374b;
  color: #d5d2b8;
}