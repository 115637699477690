.cartItem {
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 28px;
  font-size: 18px;
  margin-bottom: 20px;
}

.cartDetails {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 48px;
  gap: 28px;
}

.cartItem.shipping {
  border-top: 1px solid;
  padding-top: 20px;
  grid-template-columns: 48px 2fr 1fr 1fr;
}

.cartItem.subtotal {
  grid-template-columns: 48px 2fr 1fr 1fr;
}

.cartItemImage img {
  width: 100%;
  height: auto;
}

.cartItemTitle {
  font-size: 22px;
  font-family: 'Neuzeit-Bold';
}

.cartItemAuthor {
  font-family: 'Apollo';
  font-size: 20px;
}

.itemCountControls {
  display: flex;
  gap: 10px;
  border: 2px solid #eaeaea;
}

.cartCell,
.cartItemCount {
  display: flex;
}

.cartCellContents,
.cartItemCountNo {
  margin: auto 0;
}

.cartMessage {
  font-size: 15px;
  margin: 12px 0;
  color: #edb06f;
}

.removeButton {
  color: #222;
  background: none;
  outline: none;
  cursor: pointer;
  border: 0;
  transition: all 0.2s linear;
}

.removeButton:hover {
  color: #edb06f;
}

.cartPage {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.checkoutButton,
.subtotal {
  font-family: 'Neuzeit-Bold';
}

.checkoutButton {
  font-size: 21px;
  margin-top: 20px;
  background: none;
  border: 2px solid #186580;
  color: #186580;
  font-size: 20px;
  padding: 4px 12px;
  cursor: pointer;
}

.checkoutButton:disabled {
  border-color: #eaeaea;
  color: #eaeaea;
}

iframe #root div:first-child,
#checkout #root div:first-child {
  background: none;
}

#checkoutModal {
  background: rgba(0, 0, 0, .3);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  z-index: 100;
}

#checkoutModalContent {
  width: 80%;
  max-width: 1000px;
  padding-top: 20px;
  height: 90%;
  max-height: max-content;
  overflow-y: scroll;
  margin: auto;
  border-radius: 20px;
  background: #eaeaea;
}

.modalBack {
  text-align: center;
}

.modalBack button {
  margin-bottom: 20px;
  color: #332926;
  border: 0;
  padding: 0;
  font-size: 14px;
}

.inlineAddToCart {
  border: 0;
  padding: 0;
  margin: 0;
  text-align: left;
}

.cartItemCount {
  margin: 0 12px;
}

.cartIncrement {
  width: 40px;
  padding: 4px 0;
  background: none;
  border: 0;
  display: flex;
  color: #222;
  font-family: 'Neuzeit-Bold'
}

.cartIncrement:first-child {
  border-right: 2px solid #eaeaea;
}

.cartIncrement:last-child {
  border-left: 2px solid #eaeaea;
}

.cartIncrement span {
  display: inline-block;
  margin: auto;
}

@media (max-width: 1000px) {
  .cartPage {
    margin: 8%0 80px 0%;
  }
}

@media (max-width: 700px) {

  .cartPage {
    margin: 96px 0 48px 0px;
  }

  .cartCell.price {
    grid-column: span 3;
  }

  .cartItem {
    grid-template-columns: 64px 2fr;
    gap: 32px;
  }

  .cartItem.shipping,
  .cartItem.subtotal {
    grid-template-columns: 3fr 1fr;
  }

  .cartItem.shipping div:first-child,
  .cartItem.subtotal div:first-child,
  .cartItem.shipping div:last-child,
  .cartItem.subtotal div:last-child {
    display: none;
  }

  #checkoutModalContent {
    width: calc(98% - 40px);
    width: calc(98% - 40px);
  }

  .cartCellContents {
    margin: 0;
  }

  .cartPage {
    gap: 48px;
  }

  .cartItemTitle {
    font-size: 20px;
  }

  .cartDetails {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
  }

  .cartCellDetailInfo {
    grid-column: span 3;
  }
}